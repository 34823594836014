import Swiper from '@iris.interactive/handcook/public/scripts/libraries/swiper-11'

export default class UtilsComponent {
    static async customerSlider(key, config = {}, navigation = null, pagination = null) {
        const swiper = `[data-customer-slider="${key}"]`

        const options = {
            slidesPerView: 1,
            speed: 500,
            allowTouchMove: true,
            navigation: navigation
                ? {
                      nextEl: `#customer-slider-${key}-next`,
                      prevEl: `#customer-slider-${key}-prev`,
                  }
                : {},
            mousewheel: {
                forceToAxis: true,
            },
            breakpoints: {
                0: {
                    spaceBetween: 10,
                },
                650: {
                    spaceBetween: 20,
                },
                1025: {
                    spaceBetween: 40,
                },
            },
        }

        $.extend(options, config)

        $(`${swiper}> *`).wrapAll('<div class="swiper-wrapper"></div>').addClass('swiper-slide')

        if (navigation) {
            $(navigation).append(
                `<div class="customer-slider__navigation"><span id="customer-slider-${key}-prev" class="customer-slider__arrow customer-slider__arrow--prev" data-cursor="hover"></span><span class="customer-slider__separator">/</span><span id="customer-slider-${key}-next" class="customer-slider__arrow customer-slider__arrow--next" data-cursor="hover"></span></div>`,
            )
        }

        if (pagination) {
            $(pagination).each(function (index) {
                $(this).append(
                    `<div class="customer-slider__pagination">${index + 1}<span>/</span>${$(navigation).length}</div>`,
                )
            })
        }

        return await Swiper.create(swiper, options)
    }

    static initCustomerScrollReveal(target = '[data-customer-scroll-reveal]', ratio = 0.45) {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: ratio,
        }

        const handleIntersect = (entries, observer) => {
            entries.forEach((e) => {
                if (e.intersectionRatio >= ratio) {
                    $(e.target).addClass('is-loaded')
                    observer.unobserve(e.target)
                }
            })
        }

        const observer = new IntersectionObserver(handleIntersect, options)

        document.querySelectorAll(target).forEach((el) => {
            observer.observe(el)
        })
    }

    static initCustomerParallax(
        target = '[data-customer-parallax]',
        speed = -0.3,
        offset = window.innerHeight,
    ) {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const elementRect = entry.target.getBoundingClientRect()
                    const elementOrigin = elementRect.top + window.scrollY

                    window.addEventListener('scroll', () => {
                        if (elementIsNearViewport(entry.target, offset)) {
                            const scrollY = window.scrollY
                            const translateY = (scrollY - elementOrigin) * speed
                            entry.target.style.transform = `translateY(${translateY}px)`
                        } else {
                            observer.unobserve(entry.target)
                        }
                    })
                }
            })
        })

        document.querySelectorAll(target).forEach((el) => {
            observer.observe(el)
        })

        // Function to check if element is in the viewport
        function elementIsNearViewport(el, offset) {
            const rect = el.getBoundingClientRect()
            return (
                rect.top >= -offset &&
                rect.bottom - offset <=
                    (window.innerHeight || document.documentElement.clientHeight)
            )
        }
    }
}
