import UtilsComponent from '../components/utils.component'

export default class HomeService {
    isMobile = window.matchMedia('(max-width: 1024px)').matches
    isEcomode = IRISCollectionCustomer.isEcomodeActive
    speed = 1000
    swipers = []

    constructor() {
        if (!this.isEcomode) {
            this.initMap()
            this.initAgenda()
            this.initSeasons()
            UtilsComponent.initCustomerScrollReveal()

            if (this.isMobile) {
                this.initActivites()
            }
        }
    }

    initMap() {
        const selectors = '#home-map #villes > g'

        const swiper = UtilsComponent.customerSlider(
            'map',
            {
                slidesPerView: 1,
                breakpoints: {
                    0: {
                        slidesPerView: 1.1,
                        spaceBetween: 15,
                    },
                    650: {
                        slidesPerView: 2.1,
                        spaceBetween: 15,
                    },
                    1025: {
                        slidesPerView: 1,
                        spaceBetween: 40,
                    },
                },
                on: {
                    init: (e) => {
                        const key = $(e.slides[0]).data('city')
                        $(`#${key}`).addClass('is-active')
                    },
                    slideChange: (e) => {
                        const key = $(e.slides[e.activeIndex]).data('city')
                        $(selectors).removeClass('is-active')
                        $(`#${key}`).addClass('is-active')
                    },
                },
            },
            !this.isMobile ? '.section-map .section__footer' : null,
        ).then( (swiper) => {
            $(selectors).on('click', function (e) {
                const key = $(this).attr('id')
                const index = $(`[data-city="${key}"]`).index()
                swiper.slideTo(index)
            })
        })
    }

    initActivites() {
        this.swipers.activites = UtilsComponent.customerSlider('activites', {
            breakpoints: {
                0: {
                    slidesPerView: 1.4,
                    spaceBetween: 10,
                },
                650: {
                    slidesPerView: 2.4,
                    spaceBetween: 20,
                },
            },
        })
    }

    initAgenda() {
        this.swipers.agenda = UtilsComponent.customerSlider(
            'agenda',
            {
                slidesPerView: 'auto',
                breakpoints: {
                    0: {
                        spaceBetween: 16,
                    },
                    650: {
                        spaceBetween: 20,
                    },
                    1025: {
                        spaceBetween: 40,
                    },
                },
            },
            !this.isMobile ? '.section-agenda .section__footer' : null,
        )
    }

    initSeasons() {
        const target = '[data-element="season"]'

        const swiper = UtilsComponent.customerSlider('seasons', {
            autoplay: {
                delay: 5000,
            },
            effect: 'fade',
            fadeEffect: {
                crossFade: true,
            },
            on: {
                init: () => {
                    $(target).eq(0).addClass('is-active')
                },
                activeIndexChange: function () {
                    $(target).removeClass('is-active is-prev')
                    $(target).eq(this.realIndex).addClass('is-active')

                    if (this.realIndex > 0) {
                        for (let i = 0; i <= this.realIndex - 1; i++) {
                            $(target).eq(i).addClass('is-prev')
                        }
                    }
                },
            },
        }).then( (swiper) => {
            $(target).on('click', function () {
                const index = $(this).index()
                swiper.slideTo(index)
            })
        })
    }
}
